import { useState, useRef } from 'react';
import Router from 'next/router';

import Header from './HeaderWithoutBehaviour';

/* Utilities */
import { focusAndOpenKeyboard } from '../../utils/utility';

/* Global Values */
import GlobalValues from '../../static-data/global-values.json';

const mainMenuItems = GlobalValues?.data?.mainMenu?.menuItems?.edges;

const disableScroll = (disable) => {
	if (disable) {
		document.documentElement.classList.add('disable-scroll');
	} else {
		document.documentElement.classList.remove('disable-scroll');
	}
};

const setScrollPosition = (top, currentScrollPos) => {
	if (top) {
		document.body.scrollTop = 0; // safari
		document.documentElement.scrollTop = 0; // others
	} else {
		// set scroll to old position
		document.body.scrollTop = currentScrollPos; // safari
		document.documentElement.scrollTop = currentScrollPos; // others
	}
};

/**
 * @summary The Header, with behaviour and main menu items from the Global Values.
 * @returns {JSX.Element}
 */
export default function HeaderWithBehaviour() {
	const [menuOpen, setMenuOpen] = useState(false);
	const [searchOpen, setSearchOpen] = useState(false);
	const scrollPos = useRef(0);

	const searchId = 'headerSearchInput';

	Router.events.on('routeChangeComplete', () => {
		// Close nav menu if new page
		if (menuOpen === true) {
			setMenuOpen(false);
		}

		// Close search bar if new page
		if (searchOpen === true) {
			setSearchOpen(false);
			disableScroll(false);
		}

		// Remove focus outline
		if (document) {
			document.activeElement.blur();
		}
	});

	const toggleSearchMenu = () => {
		setMenuOpen(false);
		setSearchOpen(!searchOpen);

		if (!searchOpen) {
			// remember current scroll position
			scrollPos.current = document.body.scrollTop || document.documentElement.scrollTop;

			const searchInputField = document.getElementById(searchId);

			focusAndOpenKeyboard(searchInputField);
		}

		disableScroll(!searchOpen);
		setScrollPosition(!searchOpen, scrollPos.current);
	};

	const toggleNavMenu = () => {
		setSearchOpen(false);
		setMenuOpen(!menuOpen);
	};

	return (
		<Header
			mainMenuItems={mainMenuItems}
			searchId={searchId}
			isMenuOpen={menuOpen}
			isSearchOpen={searchOpen}
			toggleNavMenu={toggleNavMenu}
			toggleSearchMenu={toggleSearchMenu}
			setSearchOpen={setSearchOpen}
		/>
	);
}
