import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const APP_QUERY = gql`
	query AppQuery($url: String!) {
		redirection(url: $url) {
			target
			origin
			code
		}
		corusVideoplayerSettings {
			cesVideoplayerMain {
				licensekey
				playerversion
				themecolor
				route
			}
		}
	}
`;
