import htmlParser, { domToReact } from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { STANDARD_CONFIG } from '../components/Text/HtmlWrapper';
import { replaceLinkWithNextLink } from './utility';
import { isLinkWithHref } from './htmlParserPredicates';

/**
 * @summary Parse incoming HTML into React components and ensure that any anchor elements
 * that open in a new tab have `noreferrer`.
 * @param {string | null | undefined} html String of raw HTML.
 * @param {(s: string) => string} postSanitizationTransformer
 * @returns {string} React components rendered as initial HTML.
 */
export default function parseHtmlAndAddTabnabbing(html, postSanitizationTransformer) {
	if (!html || html === '' || html === null || typeof html !== 'string') {
		return null;
	}

	const options = {
		replace: (domNode) => {
			// Add Tabnabbing by replacing <a> without both rel="noopener noreferrer" in attributes.
			if (isLinkWithHref(domNode)) {
				return replaceLinkWithNextLink(domNode);
			}
			return domToReact(domNode, options);
		},
	};

	const sanitizedHtml = DOMPurify.sanitize(html, STANDARD_CONFIG.config);

	const transformedHtml = postSanitizationTransformer ? postSanitizationTransformer(sanitizedHtml) : sanitizedHtml;

	const parsedHtml = htmlParser(transformedHtml, options);

	// eslint-disable-next-line consistent-return
	return parsedHtml;
}
