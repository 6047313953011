import * as React from 'react';

function PrintIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<title>Artboard 24</title>
			<path d="M31 26h-9v-4H10v4H1V10h30zm-7-2h5V12H3v12h5v-4h16z" />
			<path d="M24 12H8V3h16zm-14-2h12V5H10zM24 29H8v-9h16zm-14-2h12v-5H10zM5.417 14.083h4v2h-4z" />
			<text
				y={47}
				fontSize={5}
				fontWeight="bold"
				fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
			>
				Created by Phoenix Dungeon
			</text>
			<text
				y={52}
				fontSize={5}
				fontWeight="bold"
				fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
			>
				from the Noun Project
			</text>
		</svg>
	);
}

export default PrintIcon;
