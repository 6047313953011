import React, {
	useEffect, useState, useCallback, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import FocusTrap from 'focus-trap-react';
import addTabnabbing from '../../utils/addTabnabbing';
import CorusImage from '../CorusImage/CorusImage';
import { getImageHeightAspectRatio } from '../../utils/utility';

/**
 * @summary Render the popup
 */
export default function PopupMessage({
	conditional, parameter, heading, content, image, cta, referrerUrl,
}) {
	const router = useRouter();
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [referrer, setReferrer] = useState('');
	const popupRef = useRef(null);

	useEffect(() => {
		const referrerOrigin = document.referrer;
		setReferrer(referrerOrigin);
	}, []);

	const imageWidth = 640;
	const imageHeight = useMemo(() => (image?.mediaDetails
		? getImageHeightAspectRatio(image.mediaDetails.width, image.mediaDetails.height, imageWidth)
		: 0), [image]);

	const imageURL = useMemo(() => image?.sourceUrl, [image]);
	const imageAlt = useMemo(() => image?.altText, [image]);

	// Check if the URL contains redirect parameter
	const isRedirect = useMemo(() => router.query[parameter] !== undefined, [router.query, parameter]);

	// Check if referrer matches the provided referrerUrl
	const isReferrerMatch = useMemo(() => {
		if (!referrerUrl) return true;
		if (!referrer) return false;

		try {
			const referrerUrlObj = new URL(referrerUrl);
			const referrerObj = new URL(referrer);
			return referrerUrlObj.origin === referrerObj.origin;
		} catch (e) {
			return false;
		}
	}, [referrerUrl, referrer]);

	useEffect(() => {
		let shouldShow = false;

		if (conditional) {
			shouldShow = isRedirect;
		} else if (referrerUrl) {
			shouldShow = isReferrerMatch;
		} else {
			try {
				const lastClosedTime = localStorage.getItem('popup-closed-time');
				if (!lastClosedTime) {
					shouldShow = true;
				} else {
					const oneDay = 24 * 60 * 60 * 1000;
					const timeDifference = Date.now() - parseInt(lastClosedTime, 10);
					shouldShow = timeDifference >= oneDay;
				}
			} catch (e) {
				shouldShow = true;
			}
		}

		setIsPopupOpen(shouldShow);
	}, [conditional, referrerUrl, isReferrerMatch, isRedirect]);

	useEffect(() => {
		document.body.classList.toggle('popup-active', isPopupOpen);
		return () => {
			document.body.classList.remove('popup-active');
		};
	}, [isPopupOpen]);

	const removeQueryParam = useCallback((paramToRemove) => {
		if (!paramToRemove) return;
		const { query, pathname } = router;
		const newQuery = { ...query };
		delete newQuery[paramToRemove];
		router.replace({ pathname, query: newQuery });
	}, [router]);

	const handleClosePopup = useCallback(() => {
		setIsPopupOpen(false);
		removeQueryParam(parameter);
		if (!conditional && !referrerUrl && !isRedirect) {
			try {
				localStorage.setItem('popup-closed-time', Date.now().toString());
			} catch (e) {
				// Errors
			}
		}
	}, [conditional, referrerUrl, parameter, removeQueryParam, isRedirect]);

	const handleKeyDown = useCallback((event) => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			event.stopPropagation();
			handleClosePopup();
		}
	}, [handleClosePopup]);

	if (!isPopupOpen) return null;

	return (
		<FocusTrap
			active
			ref={popupRef}
			focusTrapOptions={{
				clickOutsideDeactivates: true,
				escapeDeactivates: true,
				fallbackFocus: '.popup-close-button',
			}}
		>
			<div className="popup">
				<div
					className="popup-wrapper"
					role="dialog"
					aria-modal="true"
					aria-labelledby={heading ? 'popup-heading' : undefined}
				>
					{imageURL && (
						<div className="popup-image">
							<CorusImage
								src={imageURL}
								width={imageWidth}
								height={imageHeight}
								alt={imageAlt}
							/>
						</div>
					)}
					<div className="popup-main">
						{heading && (
							<h1 id="popup-heading" className="popup-heading">
								{heading}
							</h1>
						)}
						{content && (
							<div className="popup-content">
								{addTabnabbing(content)}
							</div>
						)}
						<button
							className="popup-close-button"
							aria-label="Close popup"
							type="button"
							onClick={handleClosePopup}
							onKeyDown={handleKeyDown}
						>
							{cta?.title ? cta?.title : 'Got It'}
						</button>
					</div>
				</div>
			</div>
		</FocusTrap>
	);
}

PopupMessage.propTypes = {
	referrerUrl: PropTypes.string,
	conditional: PropTypes.bool,
	parameter: PropTypes.string,
	heading: PropTypes.string,
	content: PropTypes.string,
	image: PropTypes.shape({
		sourceUrl: PropTypes.string,
		altText: PropTypes.string,
		mediaDetails: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),
	}),
	cta: PropTypes.shape({
		target: PropTypes.string,
		title: PropTypes.string,
		url: PropTypes.string,
	}),
};

PopupMessage.defaultProps = {
	referrerUrl: '',
	conditional: false,
	parameter: '',
	heading: '',
	content: '',
	image: null,
	cta: null,
};
