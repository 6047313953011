import Script from 'next/script';
import globalValues from '../../../static-data/global-values.json';

const {
	cesDataAdsAdChoicesUrlEn,
	cesDataAdsAdChoicesUrlFr,
	cesDataAdsDisableAdChoices,
	cesDataAdsFrenchAdChoices,
} = globalValues?.data?.corusAdsSettings;

export default function AdChoicesScript() {
	return (
		<>
			{ !cesDataAdsDisableAdChoices && (
				<>
					{ cesDataAdsFrenchAdChoices
						? (
							<Script
								id="ad-choices"
								src={cesDataAdsAdChoicesUrlFr}
								strategy="lazyOnload"
							/>
						)
						: (
							<Script
								id="ad-choices"
								src={cesDataAdsAdChoicesUrlEn}
								strategy="lazyOnload"
							/>
						)}
				</>
			)}
		</>
	);
}
