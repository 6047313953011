import PropTypes from 'prop-types';

/**
 * @summary The Menu Toggle Button
 * @param {import('./HeaderTypes').MenuToggleButtonProps} props
 * @returns {JSX.Element}
 */
export default function MenuToggleButton({
	toggleNavMenu = () => {},
	isMenuOpen = false,
	isMobileOnly = false,
}) {
	return (
		<div
			className={[
				'header-menu-button',
				isMobileOnly ? 'd-block d-none-lg' : null,
			].join(' ')}
		>
			<button
				type="button"
				className="menu-toggle-button"
				onClick={toggleNavMenu}
				aria-label="menu"
				aria-expanded={isMenuOpen}
			>
				<div className="menu-toggle-button-top-line" />
				<div className="menu-toggle-button-middle-line" />
				<div className="menu-toggle-button-bottom-line" />
			</button>
		</div>
	);
}

MenuToggleButton.propTypes = {
	toggleNavMenu: PropTypes.func,
	isMenuOpen: PropTypes.bool,
	isMobileOnly: PropTypes.bool,
};

MenuToggleButton.defaultProps = {
	toggleNavMenu: () => {},
	isMenuOpen: false,
	isMobileOnly: false,
};
