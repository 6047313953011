/* eslint-disable max-len */
import Script from 'next/script';

export default function AmazonScript() {
	return (
		<Script
			id="amazon-bidding"
			dangerouslySetInnerHTML={{
				__html: `
					// Load the APS JavaScript Library
					!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");

					// Initialize the Library
					apstag.init({
						pubID: "3311",
						adServer: "googletag",
						deals: true,
						videoAdServer: "DFP",
						bidTimeout: 1200,
					});

					// Set a flag so inline video (not embedded via iframe) skips the apstag.init
					window.apstag_initialized = true;`,
			}}
		/>
	);
}
