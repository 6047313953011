import PropTypes from 'prop-types';

import NavMenuItem from './NavMenuItem';

/**
 * @summary The Nav Menu.
 * @param {import('./HeaderTypes').NavMenuProps} props
 * @returns {JSX.Element}
 */
export default function NavMenu({ mainMenuItems }) {
	return (
		<>
			{
				mainMenuItems?.map((item) => {
					const {
						cssClasses, url, target, label, id,
					} = item.node;

					const cssClass = [
						'nav-menu-item',
						cssClasses?.join(' ') ?? null,
					].join(' ');

					return (
						<li className={cssClass} key={id}>
							<NavMenuItem url={url} target={target} label={label} />
						</li>
					);
				})
			}
		</>
	);
}

NavMenu.propTypes = {
	mainMenuItems: PropTypes.arrayOf(PropTypes.shape({
		node: PropTypes.shape({
			url: PropTypes.string.isRequired,
			target: PropTypes.string,
			label: PropTypes.string.isRequired,
		}),
	})).isRequired,
};
