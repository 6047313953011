/* eslint-disable max-len */
import PropTypes from 'prop-types';
import Script from 'next/script';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { DFPManager } from 'react-dfp';

export default function PermutiveScript({ iabData, targetingArguments }) {
	const ORGANIZATION_ID = 'f7e98148-cb09-4cf1-9b9f-b5aee3465d6e';
	const WORKSPACE_ID = 'f7e98148-cb09-4cf1-9b9f-b5aee3465d6e';
	const WORKSPACE_API_KEY = '2e14a473-6c86-4a13-b06d-59143665c372';

	const router = useRouter();

	const permContentDL = {
		iab: {},
	};
	const permSearchDL = {};
	/** @type {string[]} */
	const gamContentCategories = [];

	const assignDLValues = (permname, permtags, permtype) => {
		permContentDL.name = permname;
		permContentDL.keywords = permtags;
		permContentDL.type = permtype;
	};

	const getPageTitle = () => {
		let pageTitle = document.title;
		if (pageTitle.indexOf('-') > -1) {
			pageTitle = pageTitle.split('-');
			pageTitle.pop();
			pageTitle = pageTitle.join('-');
		}
		return pageTitle;
	};

	const buildDLContext = (dataLayer) => {
		if (typeof dataLayer.content === 'undefined') {
			return;
		}
		const {
			showName, tags, pageTitle, type,
		} = dataLayer.content;
		if (showName && showName !== '') {
			tags.push(showName);
		}
		assignDLValues(
			pageTitle || getPageTitle(),
			tags || [],
			type || '',
		);
	};

	const buildAdsContext = () => {
		let permType = '';
		if (typeof targetingArguments.section !== 'undefined') {
			// Sections
			permContentDL.sections = [targetingArguments.section];
			// Content type
			permType = targetingArguments.section;
		}

		// Content title
		const permName = getPageTitle();

		// Content tags
		let permTags = [];
		if (typeof targetingArguments.tags !== 'undefined') {
			permTags = targetingArguments.tags.split(',');
		}
		if (permType === 'shows') {
			permTags.push(permName);
		}
		assignDLValues(permName, permTags, permType);
	};

	/**
	 * Build the IAB Context for the content.
	 * @param {{ categories?: Array<{ category?: string }>, entities?: Array<{ entity?: string }>}} iab 
	 */
	const buildIabContext = (iab) => {
		const { categories, entities } = iab;

		/**
		 * Build IAB categories
		 * @type {(string[][] | false)}
		 */
		const iabCategories = categories?.length > 0 ? categories.map((category) => {
			const items = [];
			/**
			 * Category labels appear as a path, e.g., Food and Drink/Desserts/A/B/etc.
			 * @type {(string[] | null | undefined)}
			 */
			const categoryLabels = category?.category?.split('/');

			// Extract top level categories - e.g., "Food and Drink" in the example above.
			gamContentCategories.push(categoryLabels[0]);

			// Extract nested level categories, creating an entry for each level.
			// e.g., `[ "Food and Drink", "Food and Drink/Desserts", "Food and Drink/Desserts/A"]`
			if (categoryLabels.length > 1) {
				categoryLabels.forEach((_name, index) => {
					items.push(categoryLabels.slice(0, index + 1).join('/'));
				});
			} else if (categoryLabels.length === 1) {
				items.push(categoryLabels[0]);
			}
			return items;
		}) : [];

		// Build IAB Categories.
		permContentDL.iab.categories = [...new Set(iabCategories.flat())];

		// Build IAB Tags
		permContentDL.iab.tags = entities?.map((e) => e.entity) ?? [];
	};

	const init = () => {
		if (document.location.pathname === '/') {
			assignDLValues('homepage', ['homepage'], 'homepage');
			return;
		}
		if (document.location.pathname.indexOf('/search/') > -1) {
			permSearchDL.term = decodeURI(document.location.pathname.replace(/\/search\//gi, ''));
			return;
		}
		if (typeof window.dataLayerCorus !== 'undefined') {
			buildDLContext(window.dataLayerCorus);
		} else if (typeof targetingArguments !== 'undefined') {
			buildAdsContext();
		}
		if (iabData) {
			buildIabContext(iabData);
		}
	};

	const setPageContext = () => {
		// eslint-disable-next-line 
		!(function (n, e, i) { if (!n) { n = n || {}, window.permutive = n, n.q = [], n.config = i || {}, n.config.apiKey = e, n.config.environment = n.config.environment || 'production'; for (let o = ['addon', 'identify', 'track', 'trigger', 'query', 'segment', 'segments', 'ready', 'on', 'once', 'user', 'consent'], r = 0; r < o.length; r++) { const t = o[r]; n[t] = (function (e) { return function () { const i = Array.prototype.slice.call(arguments, 0); n.q.push({ functionName: e, arguments: i }); }; }(t)); } } }(window.permutive, WORKSPACE_API_KEY, {}));
		window.permutive.addon('web', { page: { content: permContentDL, search: permSearchDL } });
	};

	const setTargeting = () => {
		const kvs = localStorage.getItem('_pdfps');
		DFPManager.getGoogletag().then((googletag) => {
			if (typeof googletag !== 'undefined') {
				googletag.cmd.push(() => {
					googletag.pubads().setTargeting('permutive', kvs ? JSON.parse(kvs) : []);
					googletag.pubads().setTargeting('contentcat', [...new Set(gamContentCategories)].join(','));
				});
			}
		});
	};

	useEffect(() => {
		init();
		setPageContext();
		setTargeting();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router]);

	return (
		<>
			<Script
				id="permutive"
				strategy="lazyOnload"
				src={`https://${ORGANIZATION_ID}.edge.permutive.app/${WORKSPACE_ID}-web.js`}
			/>
		</>
	);
}

PermutiveScript.propTypes = {
	iabData: PropTypes.shape({
		categories: PropTypes.array.isRequired,
		entities: PropTypes.array.isRequired,
	}),
	targetingArguments: PropTypes.object,
};

PermutiveScript.defaultProps = {
	iabData: null,
	targetingArguments: {},
};
