import PropTypes from 'prop-types';

import ComboboxSearch from './ComboboxSearch';

import { noop } from '../../utils/utility';

import SearchIcon from '../../inline-images/search-icon.svg';

/**
 * @summary Renders the Predictive Search Form.
 * @param {import('./PredictiveSearchFormProps').PredictiveSearchFormProps} props
 * @returns {JSX.Element}
 */
export default function PredictiveSearchForm({
	searchInputId,
	searchInputLabelId,
	searchInputName,
	searchInputRef,
	searchPlaceholder,
	submitButtonLabel,
	formClasses = [],
	handleSubmit = noop,
	handleBlur = noop,
	handleFocus = noop,
	handleSearchTermChange = noop,
	handleSuggestedTermSelection = noop,
	isCompact = false,
	searchSuggestions = [],
}) {
	return (
		<form
			onSubmit={handleSubmit}
			action="/search"
			className={formClasses.join(' ')}
		>
			<ComboboxSearch
				suggestions={searchSuggestions ?? []}
				handleBlur={handleBlur}
				handleFocus={handleFocus}
				handleSearchTermChange={handleSearchTermChange}
				handleSuggestedTermSelection={handleSuggestedTermSelection}
				placeholder={searchPlaceholder}
				inputId={searchInputId}
				inputLabelId={searchInputLabelId}
				inputName={searchInputName}
				inputRef={searchInputRef}
				label="Search"
			/>
			{!isCompact ? (
				<button aria-label="Search" type="submit">
					{submitButtonLabel}
				</button>
			) : (
				<button aria-label="Search" type="submit">
					<SearchIcon />
				</button>
			)}
		</form>
	);
}

PredictiveSearchForm.propTypes = {
	searchInputId: PropTypes.string.isRequired,
	searchInputLabelId: PropTypes.string.isRequired,
	searchInputName: PropTypes.string.isRequired,
	searchInputRef: PropTypes.object.isRequired,
	searchPlaceholder: PropTypes.string.isRequired,
	submitButtonLabel: PropTypes.string.isRequired,
	formClasses: PropTypes.array,
	handleSubmit: PropTypes.func,
	handleBlur: PropTypes.func,
	handleFocus: PropTypes.func,
	handleSearchTermChange: PropTypes.func,
	handleSuggestedTermSelection: PropTypes.func,
	isCompact: PropTypes.bool,
	searchSuggestions: PropTypes.arrayOf(PropTypes.shape({
		slug: PropTypes.string,
		term: PropTypes.string,
	})),
};

PredictiveSearchForm.defaultProps = {
	formClasses: [],
	handleSubmit: noop,
	handleBlur: noop,
	handleFocus: noop,
	handleSearchTermChange: noop,
	handleSuggestedTermSelection: noop,
	isCompact: false,
	searchSuggestions: [],
};
