import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import { decode } from 'html-entities';

// !!! DO NOT DISABLE ESLINT'S REACT/NO-DANGER RULE !!!

// ! AT ANY GIVEN TIME, MOST WARNINGS REGARDING REACT/NO-DANGER
// ! SHOULD OCCUR ONLY HERE.

/** Type Aliases */
/** @typedef {import('./HtmlWrapperTypes').CustomDOMPurifyConfig} Config */
/** @typedef {Array<import('./HtmlWrapperTypes').AcceptedContainerElement>} Container */
/** @typedef {Array<import('./HtmlWrapperTypes').ConfigLevel>} Level */

/**
 * Accepted Container Elements for HtmlWrapper.
 * @type {Array<Container>}
 */
const ACCEPTED_CONTAINER_ELEMENTS = ['article', 'div', 'section'];

/**
 * Accepted Configuration Levels.
 * @type {Array<Level>}
 */
const CONFIG_LEVELS = ['scripted-iframe', 'iframe', 'standard', 'strict'];

/**
 * Strict DOMPurify configuration.
 * Forbids all tags.
 * @type {Config}
 */
export const STRICT_CONFIG = {
	name: 'default',
	config: {
		ALLOWED_TAGS: [],
	},
};

/**
 * Standard DOMPurify configuration.
 * Allows `target` attributes so links may open in a new tab.
 * @type {Config}
 */
export const STANDARD_CONFIG = {
	name: 'standard',
	config: {
		ADD_ATTR: ['target'],
	},
};

/**
 * `iframe`-friendly DOMPurify configuration.
 * Allows `iframe`s with `allow`, `allowfullscreen`, `frameborder`, `scrolling`, and `target` attributes.
 * @type {Config} 
 */
export const IFRAME_CONFIG = {
	name: 'iframe',
	config: {
		ADD_TAGS: ['iframe'],
		ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
	},
};

/**
 * Scripted `iframe`-friendly DOMPurify configuration.
 * e.g., Twitter and Tiktok's embeds use a script to hydrate their embeds.
 * `IFRAME_CONFIG` discards these script tags.
 * Allows `iframe`s with `allow`, `allowfullscreen`, `frameborder`, `scrolling`, and `target` attributes.
 * @type {Config} 
 */
export const SCRIPTED_IFRAME_CONFIG = {
	name: 'scripted-iframe',
	config: {
		ADD_TAGS: ['iframe', 'script'],
		ADD_ATTR: [
			'allow',
			'allowfullscreen',
			'async',
			'data-instgrm-payload-id',
			'frameborder',
			'scrolling',
			'target',
		],
	},
};

/**
 * @type {Record<Pick<Config, 'name'>, Config>}
 */
export const configMap = {
	scriptedIframe: SCRIPTED_IFRAME_CONFIG,
	iframe: IFRAME_CONFIG,
	standard: STANDARD_CONFIG,
	strict: STRICT_CONFIG,
};

/**
 * @summary Wrapper for raw HTML. Sanitizes input given a DOMPurify config level.
 * Defaults to returning a `div` with a `ADD_ATR: [ 'target' ]` config.
 * 
 * Use `configLevel="iframe"` to allow simple `iframe` embeds.
 * 
 * Some embeds, like Twitter and Tiktok's embeds, initially render a blockquote,
 * and use a script to hydrate it into an `iframe`. Use `configLevel="scripted-iframe"`
 * to allow the scripts in these embeds.
 * 
 * Pass `needsDecoding` if the raw HTML requires decoding before sanitization.
 * You may need this if  the contents use HTML entities
 * or if you called `react-dom/server`'s `renderToString()` on the contents.
 * 
 * @param {import('./HtmlWrapperTypes').Props} props
 * @returns {JSX.Element}
 * 
 * @example
 * ```javascript
 * function AReactComponent({ userGeneratedHtml }) {
 *   return (
 *     <HtmlWrapper
 *       html={userGeneratedHtml}
 *       className="format-html"
 *     />
 *   )
 * }
 * ```
 * This will return a `<div class="format-html">...</div>` with the contents sanitized
 * such that `target` attributes are kept.
 */
export default function HtmlWrapper({
	html,
	as: ContainerElement = 'div',
	configLevel = 'standard',
	needsDecoding = false,
	id,
	className,
}) {
	/**
	 * Get the requested DOMPurify config; otherwise use `STANDARD_CONFIG`.
	 */
	const { config } = CONFIG_LEVELS.includes(configLevel) ? configMap[configLevel] : STANDARD_CONFIG;

	if (ACCEPTED_CONTAINER_ELEMENTS.includes(ContainerElement) && typeof html === 'string') {
		/**
		 * The raw HTML may require decoding if, for instance, `renderToString` processed it first, resulting
		 * in HTML entities such as < and > being sanitized to &lt; and &gt;.
		 * 
		 * Decoding them here restores those HTML entities,
		 * and then we sanitize it before finally rendering it.
		 */
		const htmlToSanitize = needsDecoding ? decode(html) : html;

		return (
			<ContainerElement
				id={id}
				className={className}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(htmlToSanitize, config),
				}}
				role={ContainerElement === 'section' ? 'document' : undefined}
			/>
		);
	}
	return <></>;
}

HtmlWrapper.propTypes = {
	html: PropTypes.string,
	as: PropTypes.oneOf(ACCEPTED_CONTAINER_ELEMENTS),
	id: PropTypes.string,
	className: PropTypes.string,
	configLevel: PropTypes.oneOf(CONFIG_LEVELS),
	needsDecoding: PropTypes.bool,
};

HtmlWrapper.defaultProps = {
	html: undefined,
	as: 'div',
	id: undefined,
	className: undefined,
	configLevel: 'standard',
	needsDecoding: false,
};
