import { gql } from '@apollo/client';

export const POPUP_QUERY = gql`
query POPUP_QUERY {
	popupSettings {
		popupSettings {
			popupEnable
			popupReferrerUrl
			popupConditional
			popupParameter
			popupContent
			popupHeading
			popupImage {
				id
				altText
				sourceUrl
				mediaDetails {
					height
					width
				}
			}
			popupCta {
				target
				title
				url
			}
		}
	}
}
`;
