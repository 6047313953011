import { useEffect, useState } from 'react';
import Router from 'next/router';

/**
 * @summary Use the router's URL history.
 * 
 * Record information about URL parameters to session storage.
 */
export default function useRouterUrlHistory() {
	const [isBackPressed, setIsBackPressed] = useState(false);
	useEffect(() => {
		const handleBeforeHistoryChange = () => {
			const previousPath = Router.asPath;
			sessionStorage.setItem('pagePrevious', previousPath);
		};

		const handleOnRouteChangeComplete = () => {
			const currentTagPage = Router.asPath;
			sessionStorage.setItem('pageCurrent', currentTagPage);

			const pageCurrent = sessionStorage.getItem('pageCurrent');
			const pagePrevious = sessionStorage.getItem('pagePrevious');

			if (!isBackPressed && (pagePrevious.split('?')[0] !== Router.asPath.split('?')[0])) {
				sessionStorage.setItem(pageCurrent, JSON.stringify({ x: 0, y: 0 }));
				window.scrollTo(0, 0);
			}

			setIsBackPressed(false);
		};

		Router.events.on('beforeHistoryChange', handleBeforeHistoryChange);
		Router.events.on('routeChangeComplete', handleOnRouteChangeComplete);
		Router.beforePopState(() => {
			setIsBackPressed(true);
			return true;
		});

		return () => {
			Router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
			Router.events.off('routeChangeComplete', handleOnRouteChangeComplete);
			Router.beforePopState(() => true);
		};
	});
}
