import PropTypes from 'prop-types';
import Social from './Social';

export default function SocialIcon({
	type, alt, title, className,
}) {
	const { displayName, Icon } = Social[type];

	const iconAlt = alt || displayName;
	const iconTitle = title || displayName;
	const iconClass = className || `social-icon social-icon--${type}`;

	return (
		<Icon
			alt={iconAlt}
			title={iconTitle}
			className={iconClass}
		/>
	);
}

SocialIcon.propTypes = {
	type: PropTypes.string.isRequired,
	alt: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
};

SocialIcon.defaultProps = {
	alt: null,
	title: null,
	className: null,
};
