/**
 * @summary The "Skip to main content" link.
 * @returns {JSX.Element}
 */
export default function SkipToMainContentLink() {
	return (
		<a
			className="skip-to-content-link"
			href="#main"
		>
			Skip to main content
		</a>
	);
}
