import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { DFPSlotsProvider } from 'react-dfp';
import AdChoicesScript from './scripts/AdChoicesScript';
import AmazonScript from './scripts/AmazonScript';
import IndexExchangeScript from './scripts/IndexExchangeScript';
import PermutiveScript from './scripts/PermutiveScript';
import MoatScript from './scripts/MoatScript';
import globalValues from '../../static-data/global-values.json';

const {
	cesDataAdsEnableAmazonBidding,
	cesDataAdsIxHeaderBiddingScript,
	ceAdsMoatIvtEnabeled,
	cesDataAdsServerEnvironment: adServerEnv = 'corus-uat-ads',
	cesDataAdsAccount,
} = globalValues?.data?.corusAdsSettings;

export const AD_PATH = cesDataAdsAccount || '/6872/shaw.flavournetwork.ca';

export const getAdTargetingArguments = (router, article, recipe) => {
	let page = '';
	let section = '';

	const post = article || recipe;
	const featuredTag = post ? post?.featuredTag?.recipeDetailFeaturedTag?.slug : '';
	const path = router.asPath.split('?')[0];
	const pathArray = path.split('/').filter((s) => s !== '');
	const pageType = pathArray[0];
	if (pathArray.length > 1) { // Article or recipe
		if (pageType === 'article' || pageType === 'recipe') {
			page = `${pageType},${pathArray[1]}`;
			section = featuredTag;
		} else {
			if (pageType === 'tag') { // Tag page
				section = pathArray[pathArray.length - 1];
			} else {
				section = pageType;
			}
			page = pathArray[pathArray.length - 1];
		}
	} else if (pathArray.length === 1) { // Root level page
		page = pageType;
		section = pageType;
	} else if (pathArray.length === 0) { // Homepage
		page = 'homepage';
		section = 'homepage';
	}

	const tagsArray = post ? post.tags?.nodes : [];
	const tags = encodeURI(tagsArray.map((tag) => tag.slug).join(','));
	const server = adServerEnv;
	const targetingArguments = {
		page,
		section,
		server,
		tags,
	};

	return targetingArguments;
};
export default function AdsProvider({
	children, article, recipe,
}) {
	const router = useRouter();
	const targetingArguments = getAdTargetingArguments(router, article, recipe);

	return (
		<DFPSlotsProvider
			dfpNetworkId={AD_PATH}
			adUnit={AD_PATH}
			collapseEmptyDivs
			disableInitialLoad
			singleRequest={false}
			targetingArguments={targetingArguments}
		>
			{ ceAdsMoatIvtEnabeled && <MoatScript /> }
			{ cesDataAdsEnableAmazonBidding && <AmazonScript /> }
			{ cesDataAdsIxHeaderBiddingScript && <IndexExchangeScript /> }
			<AdChoicesScript />
			<PermutiveScript
				iabData={article?.iabClassificationData || recipe?.iabClassificationData}
				targetingArguments={targetingArguments}
			/>
			{ children }
		</DFPSlotsProvider>
	);
}

AdsProvider.propTypes = {
	children: PropTypes.shape({}).isRequired,
	article: PropTypes.object,
	recipe: PropTypes.object,
};

AdsProvider.defaultProps = {
	article: null,
	recipe: null,
};
