import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import {
	Combobox, ComboboxInput, ComboboxOption, ComboboxOptions,
} from '@headlessui/react';
import onClickOutside from 'react-onclickoutside';

import { decode } from 'html-entities';

import { MINIMUM_SEARCH_TERM_LENGTH } from '../../hooks/useServerSideSuggestionMatch';
import useEscapeKey from '../../hooks/useEscapeKey';

import { noop, uniqByKey } from '../../utils/utility';

function VerbatimOption({ term }) {
	return (
		<ComboboxOption value={term} className="combobox-option">
			<ComboboxOptionItem searchTerm={term} />
		</ComboboxOption>
	);
}

VerbatimOption.propTypes = {
	term: PropTypes.string.isRequired,
};

/**
 * @summary Renders a Combobox search box.
 *
 * Please note that this component imports `'@reach/combobox/styles.css'`,
 * which provides some basic styling for the suggestions.
 *
 * @param {import('./ComboboxSearchProps').ComboboxSearchProps} props
 * @returns {JSX.Element}
 */
function ComboboxSearch({
	inputId,
	inputLabelId,
	inputName,
	label,
	inputRef = undefined,
	suggestions = [],
	handleBlur = noop,
	handleFocus = noop,
	handleSearchTermChange = noop,
	handleSuggestedTermSelection = noop,
	placeholder = 'Search',
}) {
	// Tracks what the user has typed into the input field.
	// Used to display an alternative option if there are no suggestions.
	const [currentQuery, setCurrentQuery] = useState('');
	const compactID = 'combobox-search-input-id-compact';

	// Get the search term from the url
	const router = useRouter();
	const { term } = router.query;
	const searchTerm = term ?? '';

	useEffect(() => {
		// If using compact form, set the initial value to the search from the url
		if (inputId === compactID) {
			setCurrentQuery(searchTerm);
		}
	}, [inputId, searchTerm]);

	// Clear the current query when clicked outside the search box
	// Ignore click outside for compact search
	ComboboxSearch[`handleClickOutside_${inputId}`] = () => (inputId === compactID ? '' : setCurrentQuery(''));

	// When a term is selected, set the selected item as the current query and start the search
	const handleTermSelection = (selectedTerm) => {
		if (selectedTerm !== null) {
			setCurrentQuery(selectedTerm);
			handleSuggestedTermSelection(selectedTerm, suggestions);
		}
	};

	// Set current query to empty when escape key is pressed
	useEscapeKey(() => {
		setCurrentQuery('');
	});

	// Handle keyboard interaction
	const handleKeyDown = (event) => {
		// Prevent the default behavior of Esc key on combobox input
		if (event.key === 'Escape' || event.keyCode === 27) {
			event.preventDefault();
		}
	};

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label id={inputLabelId} className="combobox-search">
				<div className="combobox-label visually-hidden">
					{label}
				</div>
				<Combobox onChange={handleTermSelection} value={currentQuery}>
					<ComboboxInput
						id={inputId}
						name={inputName}
						className="combobox-input"
						onBlur={handleBlur}
						onFocus={handleFocus}
						onChange={(ev) => {
							setCurrentQuery(ev.target.value.trim().toLocaleLowerCase());
							handleSearchTermChange(ev);
						}}
						placeholder={placeholder}
						/* Input field must not be empty */
						required
						autoComplete="off"
						ref={inputRef}
						onKeyDown={handleKeyDown}
					/>
					{currentQuery.length > 0 && (
						<ComboboxOptions className="combobox-options">
							<VerbatimOption term={currentQuery} />
							{currentQuery.length >= MINIMUM_SEARCH_TERM_LENGTH && (
								<>
									{/* Display the first ten unique suggestions */}
									{suggestions.length > 0 && (
										uniqByKey(suggestions, 'slug')
											.slice(0, 10)
											.map((suggestion) => (
												<ComboboxOption
													key={suggestion.slug}
													value={suggestion.term}
													className="combobox-option"
												>
													<ComboboxOptionItem searchTerm={suggestion.term} />
												</ComboboxOption>
											))
									)}
								</>
							)}
						</ComboboxOptions>
					)}
				</Combobox>
			</label>
		</>
	);
}

ComboboxSearch.propTypes = {
	inputId: PropTypes.string.isRequired,
	inputLabelId: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	inputRef: PropTypes.object,
	suggestions: PropTypes.arrayOf(PropTypes.shape({
		slug: PropTypes.string,
		term: PropTypes.string,
	})),
	handleBlur: PropTypes.func,
	handleFocus: PropTypes.func,
	handleSearchTermChange: PropTypes.func,
	handleSuggestedTermSelection: PropTypes.func,
	placeholder: PropTypes.string,
};

ComboboxSearch.defaultProps = {
	inputRef: undefined,
	suggestions: [],
	handleBlur: noop,
	handleFocus: noop,
	handleSearchTermChange: noop,
	handleSuggestedTermSelection: noop,
	placeholder: 'Search',
};

const clickOutsideConfig = {
	handleClickOutside: ({ props }) => ComboboxSearch[`handleClickOutside_${props.inputId}`],
};

export default onClickOutside(ComboboxSearch, clickOutsideConfig);

/**
 * @summary Renders a combobox option item.
 * Mobile users can tap on the item to search for it right away,
 * but keyboard users can still press the enter key to enter it into the input field as normal.
 *
 * @param {object} props Props
 * @param {string} props.searchTerm Suggested term to render.
 * @returns {JSX.Element}
 */
function ComboboxOptionItem({ searchTerm }) {
	const decodedTerm = decode(searchTerm);

	return (
		(
			<span
				className="combobox-option-item"
				title={`Search for ${searchTerm}`}
			>
				{decodedTerm.toLocaleLowerCase()}
			</span>
		)
	);
}

ComboboxOptionItem.propTypes = {
	searchTerm: PropTypes.string.isRequired,
};
