import PropTypes from 'prop-types';
import Link from 'next/link';
import Social from './Social';
import SocialIcon from './SocialIcon';

export default function SocialLinks({ types }) {
	return (
		<ul className="social-links social-links-list">
			{types.map((type) => {
				const socialNetworkName = Social[type].displayName;
				const socialNetworkUrl = Social[type].url;

				return (
					<li key={type} className="social-links-list-item">
						<Link
							href={socialNetworkUrl}
							className="social-link"
							target="_blank"
							rel="noopener noreferrer"
							title={`Follow Flavour Network on ${socialNetworkName}`}
							aria-label={`Follow Flavour Network on ${socialNetworkName} (opens in new tab)`}
							data-analytics={JSON.stringify({ name: socialNetworkName, module: 'share' }, null, 2)}
						>

							<SocialIcon
								type={type}
								alt={`${socialNetworkName} Icon`}
							/>

						</Link>
					</li>
				);
			})}
		</ul>
	);
}

SocialLinks.propTypes = {
	types: PropTypes.array.isRequired,
};
