import { useMemo } from 'react';
import {
	ApolloClient, HttpLink, InMemoryCache,
} from '@apollo/client';

/** 
 * The current Apollo Client instance.  
 * @type {ApolloClient<import('@apollo/client').NormalizedCacheObject> | undefined}
 */
let apolloClient;

/**
 * @summary Creates an Apollo Client instance.
 * @returns {ApolloClient<import('@apollo/client').NormalizedCacheObject>}
 */
function createApolloClient() {
	return new ApolloClient({
		ssrMode: true, // set to true for SSR
		link: new HttpLink({
			uri: process.env.NEXT_PUBLIC_WP_GRAPHQL_URI,
			credentials: 'include',
		}),
		cache: new InMemoryCache(),
	});
}

/**
 * @summary Initialize the Apollo Client instance, hydrating from the cache if necessary.
 * @param {any | null | undefined} initialState 
 * @returns {ApolloClient<import('@apollo/client').NormalizedCacheObject>}
 */
export function initializeApollo(initialState = null) {
	const _apolloClient = apolloClient ?? createApolloClient();

	// If your page has Next.js data fetching methods that use Apollo Client,
	// the initial state gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract();

		// Restore the cache using the data passed from
		// getStaticProps/getServerSideProps combined with the existing cached data
		_apolloClient.cache.restore({ ...existingCache, ...initialState });
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') return _apolloClient;

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;
	return _apolloClient;
}

/**
 * @summary Create and return an Apollo data store using the state placed in this page's props.
 * @param {Record<string, any>} pageProps 
 * @returns {ApolloClient<import('@apollo/client').NormalizedCacheObject>}
 */
export function useApollo(initialState) {
	const store = useMemo(() => initializeApollo(initialState), [initialState]);
	return store;
}
