import { useEffect, useRef } from 'react';

const useEscapeKey = (callback) => {
	const callbackRef = useRef(callback);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		const handleEscKey = (event) => {
			if (event.key === 'Escape' || event.keyCode === 27) {
				callbackRef.current(event);
			}
		};

		document.addEventListener('keydown', handleEscKey);

		return () => {
			document.removeEventListener('keydown', handleEscKey);
		};
	}, []);
};

export default useEscapeKey;
