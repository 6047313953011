import { useEffect, useRef, useState } from 'react';

import { animateScrollTo } from '../../utils/utility';

const backToTopButtonId = 'back-to-top-button';

const backToTopButtonCssName = backToTopButtonId;

/** The Back to Top Button's accessible label. */
const backToTopButtonLabel = 'back to top';

/** The Back to Top Button's hidden text. */
const backToTopButtonText = 'Top';

/** The minimum number of pixels the user must scroll for the button to appear. */
const minimumScrollOffset = 300;

/** The minimum number of pixels the user must scroll for the button to also fade out slightly. */
const minimumScrollOffsetOpacity = 1500;

/** Duration of the scroll back to the top of the page in milliseconds. */
const scrollDuration = 700;

/**
 * Renders a "Back to Top" button in the bottom-right corner of the page, floating over the page content.
 * @returns {JSX.Element}
 */
export default function BackToTopButton() {
	const [isVisible, setIsVisible] = useState(false);
	const [shouldFadeOut, setShouldFadeOut] = useState(false);

	/**
	 * `isScrolling` works as a signal between `handleWindowScroll` and `checkBackToTop` to make sure
	 * they only happen when the user is scrolling; i.e., the effect does not accidentally fire early.
	 * This is a ref because it does not directly affect the component's visual appearance like
	 * `isVisible` and `shouldFadeOut`.
	 */
	const isScrolling = useRef(false);

	/**
	 * Determine the styles to apply to the button based on the current scroll position
	 * and the thresholds.
	 */
	const checkBackToTop = () => {
		const currentScrollPosY = window.scrollY || document.documentElement.scrollTop;

		if (currentScrollPosY > minimumScrollOffset) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
			setShouldFadeOut(false);
		}

		if (currentScrollPosY > minimumScrollOffsetOpacity) {
			setShouldFadeOut(true);
		}

		isScrolling.current = false;
	};

	/**
	 * On click, scroll back to the top of the window,
	 * using `animateScrollTo` if `window.requestAnimationFrame` is available.
	 * @type {import('react').MouseEventHandler<HTMLButtonElement>}
	 */
	const handleClick = (e) => {
		e.preventDefault();

		if (!window.requestAnimationFrame) {
			window.scrollTo(0, 0);
		} else {
			animateScrollTo(0, scrollDuration);
		}
	};

	/**
	 * On scroll, check what styles should be applied to the button.
	 * Uses `window.requestAnimationFrame` or `setTimeout` if the former is not supported
	 * to throttle this check.
	 * @type {import('react').UIEventHandler<HTMLButtonElement>}
	 */
	const handleWindowScroll = () => {
		if (!isScrolling.current) {
			isScrolling.current = true;

			if (!window.requestAnimationFrame) {
				setTimeout(checkBackToTop, 250);
			} else {
				window.requestAnimationFrame(checkBackToTop);
			}
		}
	};

	/**
	 * After rendering for the first time, add the scroll event listener to the window.
	 */
	useEffect(() => {
		window.addEventListener('scroll', handleWindowScroll);

		// Hook should run only once - React 18+ may run it twice on dev though.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<button
			id={backToTopButtonId}
			type="button"
			aria-label={backToTopButtonLabel}
			aria-hidden={!isVisible}
			onClick={handleClick}
			className={[
				backToTopButtonCssName,
				isVisible ? `${backToTopButtonCssName}--is-visible` : null,
				shouldFadeOut ? `${backToTopButtonCssName}--fade-out` : null,
			].filter(Boolean).join(' ')}
		>
			<span className="visually-hidden">{backToTopButtonText}</span>
		</button>
	);
}
