import { POPUP_QUERY } from '../components/Popup/PopupQuery';
import { initializeApollo } from './apolloClient';

export const fetchPopupSettings = async () => {
	const apolloClient = initializeApollo();

	// Popup Settings Query
	const {
		data: { popupSettings: { popupSettings } },
	} = await apolloClient.query({
		query: POPUP_QUERY,
	});

	return popupSettings;
};
