import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import BackToTopButton from '../BackToTopButton/BackToTopButton';
import SocialLinks from '../Social/SocialLinks';

import slugify from '../../utils/slugify';
import { addTrailingSlash, isOriginSameAsLocation } from '../../utils/utility';

import GlobalValues from '../../static-data/global-values.json';

const copyrightLine = GlobalValues?.data?.themeSettings?.themeConfig?.copyrightLine;
const footerMenuItems = GlobalValues?.data?.footerMenu?.menuItems?.edges;
const logoMenuItems = GlobalValues?.data?.logoMenu?.menuItems?.edges;
const isNewsletterEnabled = GlobalValues?.data?.newsletterSettings?.newsletterSettings?.enableNewsletter;

/**
 * @summary Link to the Flavour Network homepage, suitable for the footer.
 * @returns {JSX.Element}
 */
function FooterHomeLink() {
	return (
		(
			<Link href="/" className="footer-logo-link">

				<img
					className="footer-logo-image"
					src="/images/flavour-network-logo.svg"
					alt="Flavour Network homepage"
					title="Flavour Network home"
				/>

			</Link>
		)
	);
}

/**
 * @summary Social media links, suitable for the footer.
 * @returns {JSX.Element}
 */
function FooterSocialLinks() {
	return (
		<div className="footer-social">
			<div className="social-title">
				Follow Us
			</div>
			<SocialLinks types={['instagram', 'facebook', 'tiktok']} />
		</div>
	);
}

/**
 * @summary Link to the Newsletter Page.
 * @returns {JSX.Element}
 */
function FooterNewsletterLink() {
	return (
		<div className="footer-newsletter">
			<Link href="/newsletter" className="footer-newsletter-link">
				SIGN UP FOR NEWSLETTER
				<span className="visually-hidden">
					on our newsletter page
				</span>

			</Link>
		</div>
	);
}

function SROnlyNewTabMessage() {
	return (
		<span className="visually-hidden">(opens in a new tab)</span>
	);
}

/**
 * @summary Renders a footer menu item as a link inside an `li`.
 * @param {object} props Props
 * @param {string} props.url The link's URL
 * @param {string} [props.target] The URL's target.
 * @param {string} props.label The URL's label.
 * @param {string} props.cssClass String of CSS classnames to apply to the wrapper `li`.
 * @returns {JSX.Element}
 */
function FooterMenuItem({
	url, target, label, cssClass,
}) {
	const urlWithTrailingSlash = addTrailingSlash(url);
	const opensInNewTab = target === '_blank';
	const isInternal = isOriginSameAsLocation(url);

	return (
		<li className={cssClass}>
			{isInternal ? (
				(
					<Link href={urlWithTrailingSlash} className="nav-link" target={target}>

						{label}
						{opensInNewTab && <SROnlyNewTabMessage />}

					</Link>
				)
			) : (
				<a
					className="nav-link"
					href={urlWithTrailingSlash}
					rel="noopener noreferrer"
					target="_blank"
				>
					{label}
					<SROnlyNewTabMessage />
				</a>
			)}
		</li>
	);
}

FooterMenuItem.propTypes = {
	url: PropTypes.string.isRequired,
	target: PropTypes.string,
	label: PropTypes.string.isRequired,
	cssClass: PropTypes.string.isRequired,
};

FooterMenuItem.defaultProps = {
	target: undefined,
};

/**
 * @summary Renders footer menu items as a list inside a `nav`.
 * @param {object} props Props
 * @param {Array<{ node: { id: string, url: string, target?: string, label: string, cssClasses: Array<string> } }>} props.menuItems Menu items to render.
 * @returns {JSX.Element}
 */
function FooterMenu({ menuItems }) {
	return (
		<nav aria-label="Footer menu">
			<ul className="footer-menu">
				{menuItems.map((item) => {
					const {
						id, url, label, target, cssClasses,
					} = item.node;

					const cssClass = `menu-item ${cssClasses?.join(' ')}`;

					return (
						<React.Fragment key={id}>
							<FooterMenuItem url={url} target={target} label={label} cssClass={cssClass} />
						</React.Fragment>
					);
				})}
			</ul>
		</nav>
	);
}

FooterMenu.propTypes = {
	menuItems: PropTypes.arrayOf(PropTypes.shape({
		node: PropTypes.shape({
			id: PropTypes.string,
			url: PropTypes.string,
			label: PropTypes.string,
			target: PropTypes.string,
			cssClasses: PropTypes.arrayOf(PropTypes.string),
		}),
	})).isRequired,
};

/**
 * @summary Renders a footer menu item as a link inside an `li`.
 * @param {object} props Props
 * @param {string} props.url The link's URL
 * @param {string} [props.target] The URL's target.
 * @param {string} props.label The URL's label.
 * @param {string} props.cssClass String of CSS classnames to apply to the wrapper `li`.
 * @returns {JSX.Element}
 */
function LogoMenuItem({
	url, target, label, cssClass,
}) {
	const urlWithTrailingSlash = addTrailingSlash(url);
	const opensInNewTab = target === '_blank';
	const title = opensInNewTab ? `${label} (opens in new tab)` : label;

	return (
		<li className={cssClass}>
			<a
				className="nav-link"
				href={urlWithTrailingSlash}
				target={target}
				title={title}
				rel="noopener noreferrer"
			>
				<span className="visually-hidden">
					{title}
				</span>
			</a>
		</li>
	);
}

LogoMenuItem.propTypes = {
	url: PropTypes.string.isRequired,
	target: PropTypes.string,
	label: PropTypes.string.isRequired,
	cssClass: PropTypes.string.isRequired,
};

LogoMenuItem.defaultProps = {
	target: undefined,
};

/**
 * @summary Renders logo menu items as a list inside a `nav`.
 * @param {object} props Props
 * @param {Array<{ node: { id: string, url: string, target: string, label: string, cssClasses: Array<string> } }>} props.menuItems Menu items to render.
 * @returns {JSX.Element}
 */
function LogoMenu({ menuItems }) {
	return (
		<ul className="footer-bottom-logos">
			{menuItems.map((item) => {
				const {
					id, url, label, target, cssClasses,
				} = item.node;

				const cssClass = `${slugify(label)} menu-item ${cssClasses?.join(' ')}`;

				return (
					<React.Fragment key={id}>
						<LogoMenuItem url={url} target={target} label={label} cssClass={cssClass} />
					</React.Fragment>
				);
			})}
		</ul>
	);
}

LogoMenu.propTypes = {
	menuItems: PropTypes.arrayOf(PropTypes.shape({
		node: PropTypes.shape({
			id: PropTypes.string,
			url: PropTypes.string,
			label: PropTypes.string,
			target: PropTypes.string,
			cssClasses: PropTypes.arrayOf(PropTypes.string),
		}),
	})).isRequired,
};

/**
 * @summary Renders the copyright line. Replaces `yyyy` in the text with the current year.
 * @param {object} props Props
 * @param {string} props.copyright Copyright line.
 * @returns {JSX.Element}
 */
function FooterCopyright({ copyright }) {
	const currentYear = new Date().getFullYear().toString();

	return (
		<div className="footer-copyright">
			<p>{copyright.replace(/yyyy/ig, currentYear)}</p>
		</div>
	);
}

FooterCopyright.propTypes = {
	copyright: PropTypes.string.isRequired,
};

/**
 * @summary Renders a link to Corusent with the Corus Entertainment logo added via CSS.
 * @returns {JSX.Element}
 */
function FooterCorusentLink() {
	return (
		<div className="brand corus menu-item">
			<a
				className="nav-link"
				href="https://www.corusent.com/"
				target="_blank"
				rel="noopener noreferrer"
				aria-label="Corus Entertainment (opens in new tab)"
			>
				<span className="visually-hidden">Corus Entertainment (opens in new tab)</span>
			</a>
		</div>
	);
}

/**
 * @summary Renders the top portion of the footer, which renders the logo, social media links, as well as the footer menu.
 * @returns {JSX.Element}
 */
function FooterTop() {
	return (
		<section className="footer-top">
			<div className="footer-top-header">
				<FooterHomeLink />
				{/* commenting till we launch 
					<FooterSocialLinks /> 
				*/}
				{isNewsletterEnabled && <FooterNewsletterLink />}
			</div>
			<div className="footer-right">
				<FooterMenu menuItems={footerMenuItems} />
				{copyrightLine && <FooterCopyright copyright={copyrightLine} />}
			</div>
		</section>
	);
}

/**
 * @summary Renders the bottom portion of the footer, which includes a link to Corusent and other Corus brands.
 * @returns {JSX.Element}
 */
function FooterBottom() {
	return (
		<section className="footer-bottom">
			<div className="container">
				<nav className="nav logo-menu row" aria-label="More Corus Entertainment brands">
					<FooterCorusentLink />
					<LogoMenu menuItems={logoMenuItems} />
				</nav>
			</div>
		</section>
	);
}

/**
 * @summary Site footer; includes the footer menu, links to Corus brands, and the "Back to Top" button.
 * @returns {JSX.Element}
 */
export default function Footer() {
	return (
		<footer id="footer" className="footer">
			<FooterTop />
			<FooterBottom />
			<BackToTopButton />
		</footer>
	);
}
