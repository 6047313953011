import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import SearchIcon from '../../inline-images/search-icon.svg';
import { handleSearchData } from '../../utils/analytics';

const SearchBar = ({
	ariaLabel,
	id,
	compact,
	focus,
	initialValue,
	label,
	onSearch,
	placeholder,
	redirect,
	location,
}) => {
	const [value, setValue] = useState(initialValue);
	const [active, setActive] = useState(focus);
	const searchInput = useRef(null);

	const router = useRouter();

	const changeHandler = (event) => {
		setValue(event.target.value);
	};

	const focusHandler = () => {
		setActive(true);
	};

	const blurHandler = () => {
		setActive(false);
	};

	useEffect(() => {
		if (focus) {
			searchInput.current.focus();
		}
	}, [focus]);

	useEffect(() => {
		if (redirect) {
			setValue('');
		}
	}, [router.asPath]);

	const submitHandler = (event) => {
		event.preventDefault();

		// Remove leading / trailing whitespace from search term
		const searchTerm = value.trim();

		// Entering a bunch of whitespace will just reset the field.
		if (!searchTerm) {
			setValue('');
			return;
		}

		onSearch(searchTerm);

		// handle search data for analytics for search input on search pages
		if (router.pathname.includes('/search/')) {
			handleSearchData(location);
		}

		if (redirect) {
			// update location with new term
			router.push(`/search/${searchTerm}?page=1`, null);
		}
	};

	useEffect(() => {
		// update value only for search box in search page
		if (router.query.term && !redirect) setValue(router.query.term);
	}, [router.query, redirect]);

	const componentClass = compact ? 'search-bar-compact' : 'search-bar-menu';
	const activeClass = active ? `${componentClass}-active` : `${componentClass}-inactive`;

	return (
		<form onSubmit={submitHandler} action="/search" className={`${componentClass} ${activeClass}`}>
			<input
				aria-label={ariaLabel}
				autoComplete="off"
				id={id}
				value={value}
				onChange={changeHandler}
				onFocus={focusHandler}
				onBlur={blurHandler}
				type="text"
				name="term"
				placeholder={placeholder}
				ref={searchInput}
			/>
			{!compact && <button aria-label="Search" type="submit">{label}</button>}
			{compact && <button aria-label="Search" type="submit"><SearchIcon /></button>}
		</form>
	);
};

SearchBar.propTypes = {
	ariaLabel: PropTypes.string,
	compact: PropTypes.bool,
	focus: PropTypes.bool,
	id: PropTypes.string,
	initialValue: PropTypes.string,
	label: PropTypes.string,
	onSearch: PropTypes.func,
	placeholder: PropTypes.string,
	redirect: PropTypes.bool,
	location: PropTypes.string,
};

SearchBar.defaultProps = {
	ariaLabel: 'Search',
	compact: false,
	focus: false,
	id: '',
	initialValue: '',
	label: 'Go',
	onSearch: () => { },
	placeholder: '',
	redirect: false,
	location: '',
};

export default SearchBar;
