import { gql, useQuery } from '@apollo/client';

const GET_TOGGLE_PREDICTIVE_SEARCH_PILOT_QUERY = gql`
	query GetTogglePredictiveSearchPilotQuery {
		themeSettings {
			miscSettings {
				togglePredictiveSearchPilot
			}
		}
	}
`;

/**
 * @summary Based on a backend toggle,
 * determine if the website should render the new search bar.
 * @returns {boolean}
 */
export default function useShouldShowPredictiveSearchBar() {
	const res = useQuery(GET_TOGGLE_PREDICTIVE_SEARCH_PILOT_QUERY);

	const isPilotEnabledInWP = res?.data?.themeSettings?.miscSettings?.togglePredictiveSearchPilot ?? false;

	return isPilotEnabledInWP;
}
