/**
 * Search term cache.
 * @type {Record<string, Array<{ term: string, slug: string }>>}
 */
const cache = {};

/**
 * @summary Using the provided search term, build a `URL` for it against the search API.
 * @param {string} searchTerm Search term
 * @returns {URL} constructed URL.
 */
function buildAllTagsSearchUrl(searchTerm) {
	const encodedTerm = encodeURIComponent(searchTerm);

	const searchParams = `term=${encodedTerm}&type=search-terms`;

	const url = new URL(`${process.env.NEXT_PUBLIC_SEARCH_API}/?${searchParams}`);

	return url;
}

/**
 * @summary Query the search API for suggested terms based on a given term.
 * Save results to a `cache` record to avoid extraneous network calls.
 * @param {string} searchTerm Search term
 * @returns {Array<{ term: string, slug: string }>} Terms returned from search API.
 */
export default async function fetchSearchTerms(searchTerm) {
	if (cache[searchTerm]) {
		return Promise.resolve(cache[searchTerm]);
	}

	const url = buildAllTagsSearchUrl(searchTerm);

	const res = await fetch(url);

	const data = await res.json();

	const terms = data.result.map((term) => ({ term: term.title, slug: term.slug }));

	cache[searchTerm] = terms;

	return terms;
}
