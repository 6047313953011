import Head from 'next/head';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';

/**
 * Custom SEO Component for WordPress Content
 *
 * @param {import('./SeoYoastTypes').Props} props
 * @param {import('./SeoYoastTypes').Props['seo']} props.seo 
 * @see https://www.npmjs.com/package/next-seo
 *
 * @returns {JSX.Element}
 */
const Seo = ({ seo }) => {
	const fullHead = seo?.fullHead ?? '';
	return <Head>{Parser(fullHead)}</Head>;
};

Seo.propTypes = {
	seo: PropTypes.object,
};

Seo.defaultProps = {
	seo: {},
};

export default Seo;
