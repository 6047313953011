import { isVideoShortcode } from './utility';

/**
 * @summary Does this DOM Node have a certain name?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @param {string} name Name to test
 * @returns {boolean}
 */
export function nodeIs(domNode, name) {
	return (domNode?.name === name);
}

/**
 * @summary Does this DOM Node have a certain attribute?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @param {string} name 
 * @returns {boolean}
 */
export function nodeHasAttrib(domNode, name) {
	return (domNode?.attribs && domNode?.attribs?.[name]);
}

/**
 * @summary Is this DOM Node an iframe node?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isIframe(domNode) {
	return nodeIs(domNode, 'iframe');
}

/**
 * @summary Is this DOM Node an image node?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isImage(domNode) {
	return nodeIs(domNode, 'img');
}

/**
 * @summary Is this DOM Node an anchor node?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isLink(domNode) {
	return nodeIs(domNode, 'a');
}

/**
 * @summary Is this DOM Node an anchor node with a truthy href?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isLinkWithHref(domNode) {
	return (
		isLink(domNode)
		&& domNode?.attribs?.href
	);
}

/**
 * @summary Is this DOM Node a link with a non-empty `href` that opens in a new tab via `target='_blank'`?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isLinkThatOpensInNewTab(domNode) {
	return (
		isLinkWithHref(domNode)
		&& domNode?.attribs?.target === '_blank'
	);
}

/**
 * @summary Is this DOM Node a paragraph node?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isParagraph(domNode) {
	return nodeIs(domNode, 'p');
}

/**
 * @summary Is this DOM Node a script node?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {boolean}
 */
export function isScript(domNode) {
	return nodeIs(domNode, 'script');
}

/**
 * @summary Does this DOM Node have an ARIA label attribute?
 * @param {import('html-react-parser')} domNode 
 * @returns {boolean}
 */
export function hasAriaLabel(domNode) {
	return nodeHasAttrib(domNode, 'aria-label');
}

/**
 * @summary Does this DOM Node have any inline styles?
 * @param {import('html-react-parser')} domNode 
 * @returns {boolean}
 */
export function hasInlineStyles(domNode) {
	return nodeHasAttrib(domNode, 'style');
}

/**
 * @summary Does this DOM Node have any children?
 * @param {import('html-react-parser')} domNode 
 * @returns {boolean}
 */
export function hasChildren(domNode) {
	return Boolean(domNode?.children);
}

/**
 * @summary Does the DOM Node have an image elemnent as a child?
 * @param {DOMNode} domNode 
 * @returns {number | boolean}
 */
export function hasImgChild(domNode) {
	for (let i = 0; i < domNode.children.length; i += 1) {
		if (domNode.children[i].type === 'tag' && domNode.children[i].name === 'img') {
			return true;
		}
	}
	return false;
}

/**
 * @summary Does the DOM Node have a video player elemnent as a child?
 * @param {DOMNode} domNode
 * @returns {number | boolean}
 */
export function hasVideoPlayerChild(domNode) {
	for (let i = 0; i < domNode.children.length; i += 1) {
		if (isVideoShortcode(domNode.children[i]?.data) && domNode.children[i].type === 'text') {
			return true;
		}
	}

	return false;
}

/**
 * @summary Does the DOM Node have a link element as a child?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {number | boolean}
 */
export function hasLinkChild(domNode) {
	for (let i = 0; i < domNode.children.length; i += 1) {
		if (domNode.children[i].type === 'tag' && domNode.children[i].name === 'a') {
			return i;
		}
	}
	return false;
}

/**
 * @summary Does the DOM Node have a text node as a child?
 * @param {import('html-react-parser').DOMNode} domNode 
 * @returns {number | boolean}
 */
export function hasTextChild(domNode) {
	for (let i = 0; i < domNode.children.length; i += 1) {
		if (domNode.children[i].type === 'text') {
			return i;
		}
	}
	return false;
}

/**
 * @summary Does the DOM Node have a script element as a child?
 * @param {import('html-react-parser').DOMNode} domNode
 * @returns {boolean}
 */
export function hasScriptChild(domNode) {
	return domNode?.children?.some((node) => node?.type === 'script' && node?.name === 'script');
}
