import * as React from 'react';

function EmailIcon(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512.64 384.8"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<g data-name="Layer 2">
				<path d="M.12 0h512.52v32H.12zM.12 352.74h512.52v32H.12z" />
				<path d="M480.56 28.81h32V384.8h-32zM0 28.81h32V384.8H0z" />
				<path d="M17.327 36.418l20.034-24.953 238.463 191.456-20.034 24.953z" />
				<path d="M235.815 204.4l237.86-192.203 20.113 24.89-237.86 192.203z" />
			</g>
		</svg>
	);
}

export default EmailIcon;
