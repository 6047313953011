import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import { useRef } from 'react';

import PredictiveSearchBar from '../PredictiveSearch/PredictiveSearchBar';
import SearchBar from '../Search/SearchBar';

import useShouldShowPredictiveSearchBar from '../../hooks/useShouldShowPredictiveSearchBar';

/**
 * @summary The Header Search Menu.
 * @param {import('./HeaderTypes').SearchMenuProps} props
 * @returns {JSX.Element}
 */
export default function SearchMenu({
	searchId, shouldFocus, setSearchOpen, toggleSearchMenu,
}) {
	const shouldShowPredictiveSearchBar = useShouldShowPredictiveSearchBar();
	const searchMenuRef = useRef(null);

	// close the search on esc key
	const handleCloseOnESC = (event) => {
		if (event.keyCode === 27) {
			event.preventDefault();
			setSearchOpen(false);
			toggleSearchMenu();
		}
	};
	return (
		<FocusTrap
			active={shouldFocus}
			ref={searchMenuRef}
			focusTrapOptions={{ clickOutsideDeactivates: true }}
		>
			<div className="header-searchmenu" onKeyDown={handleCloseOnESC} role="presentation">
				{shouldShowPredictiveSearchBar ? (
					<PredictiveSearchBar
						searchInputId={searchId}
						searchInputLabelId="combobox-search-input-label"
						submitButtonLabel="GO"
						searchPlaceholder="Search"
						shouldFocus={shouldFocus}
						shouldRedirectOnSubmit
					/>
				) : (
					<SearchBar
						id={searchId}
						focus={shouldFocus}
						placeholder="Search"
						ariaLabel="Enter a term to search the website, press ESC to close the search"
						redirect
						location="menu"
					/>
				)}
			</div>
		</FocusTrap>
	);
}

SearchMenu.propTypes = {
	searchId: PropTypes.string.isRequired,
	shouldFocus: PropTypes.bool,
	setSearchOpen: PropTypes.func,
	toggleSearchMenu: PropTypes.func,
};

SearchMenu.defaultProps = {
	shouldFocus: false,
	setSearchOpen: () => {},
	toggleSearchMenu: () => {},
};
