import Script from 'next/script';
import Head from 'next/head';
import globalValues from '../../../static-data/global-values.json';

export default function MoatScript() {
	const { ceAdsMoatIvtPartnerCode = 'corusheader424700441673' } = globalValues?.data?.corusAdsSettings;
	const moatUrl = `https://z.moatads.com/${ceAdsMoatIvtPartnerCode}/moatheader.js`;
	return (
		<>
			<Head>
				<link rel="preload" href={moatUrl} as="script" />
				<link rel="preconnect" href="https://mb.moatads.com" />
			</Head>
			<Script
				id="moat_ivt"
				strategy="afterInteractive"
				src={moatUrl}
			/>
		</>
	);
}

export const isMoatDataAvailable = () => (
	window.moatPrebidApi
	&& typeof window.moatPrebidApi.pageDataAvailable === 'function'
	&& window.moatPrebidApi.pageDataAvailable()
);

export const setMoatSlotTargeting = (slot) => {
	if (window.moatPrebidApi) {
		window.moatPrebidApi.setMoatTargetingForSlot(slot);
		return true;
	}

	return false;
};
