import Script from 'next/script';
import globalValues from '../../../static-data/global-values.json';

const { cesDataAdsIxHeaderBiddingScript } = globalValues?.data?.corusAdsSettings;

export default function IndexExchangeScript() {
	return (
		<Script
			id="index-exchange"
			src={cesDataAdsIxHeaderBiddingScript}
			strategy="beforeInteractive"
		/>
	);
}
