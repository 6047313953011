import Link from 'next/link';

/**
 * @summary The header logo.
 * @returns {JSX.Element}
 */
export default function HeaderLogo() {
	return (
		<div className="header-logo">
			<Link
				href="/"
				className="header-logo-link"
				rel="home"
				aria-label="Link to Flavour Network homepage"
			>

				<img
					className="header-logo-image"
					src="/images/flavour-network-logo.svg"
					alt="Flavour Network home"
					title="Flavour Network home"
				/>

			</Link>
		</div>
	);
}
