import React from 'react';
import PropTypes from 'prop-types';
import PopupMessage from './PopupMessage';

/**
 * @summary Render the popup message if popup is enabled
 */
export default function Popup({ settings }) {
	if (!settings || typeof settings !== 'object') {
		return null;
	}

	const {
		popupEnable,
		popupReferrerUrl,
		popupConditional,
		popupParameter,
		popupHeading,
		popupContent,
		popupImage,
		popupCta,
	} = settings;

	if (popupEnable) {
		return (
			<PopupMessage
				referrerUrl={popupReferrerUrl}
				conditional={popupConditional}
				parameter={popupParameter}
				heading={popupHeading}
				content={popupContent}
				image={popupImage}
				cta={popupCta}
			/>
		);
	}

	return null;
}

Popup.propTypes = {
	settings: PropTypes.shape({
		popupEnable: PropTypes.bool,
		popupReferrerUrl: PropTypes.string,
		popupConditional: PropTypes.bool,
		popupParameter: PropTypes.string,
		popupHeading: PropTypes.string,
		popupContent: PropTypes.string,
		popupImage: PropTypes.object,
		popupCta: PropTypes.object,
	}),
};

Popup.defaultProps = {
	settings: PropTypes.shape({
		popupEnable: false,
		popupReferrerUrl: '',
		popupConditional: false,
		popupParameter: '',
		popupHeading: '',
		popupContent: '',
		popupImage: {},
		popupCta: {},
	}),
};
